import React from "react"
import { Link } from "gatsby"
import SEO from '../../../components/seo'

import Elements from '../../../layouts/emelents-matome.js'


const youso=   6
const part=    4
const title=   'まとめ「強弱」'
const url=     '6069945'

const iti=     youso + '-' +part
const h1=      '【' + iti + '】' + title
const mae=     '/elements/'+youso+'/'+youso +'-'+(part-1)+'/'
const ato=     '/elements/'+youso+'/'+youso +'-'+(part+1)+'/'
const mae_part= '/elements/'+(youso-1)+'/'+(youso-1)
const ato_part= '/elements/'+(youso+1)+'/'+(youso+1) +'-1/'



export default () => (

<Elements
   mae={mae}
   ato={ato}
   url={url}
   h1={h1}
>

		<SEO title={title} />




</Elements>
)
